<template>
  <div @click="close" class="modal-backdrop">
    <div @click.stop class="modal-content g-16" :class="{'no-padding':noPadding}"
         :style="{width: width+'px',height:height+'px', maxHeight: height+'px', minHeight}">
      <div class="header no-flex row full-width" v-if="!noHeading">
        <div class="title">
          <slot name="title"/>
        </div>
        <CrossIcon class="clickable" @click="close"/>
      </div>
      <div class="body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import CrossIcon from "~/components/icons/CrossIcon.vue";

const props = defineProps({
  width: {
    type: Number,
    default: 500
  },
  height: {
    type: Number,
    default: 500
  },
  minHeight: {
    type: Number,
    default: 0
  },
  noHeading: {
    type: Boolean,
    default: false
  },
  noPadding: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['close']);

function close() {
  emit('close');
}

</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/mixins.scss";

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999999999;
  cursor: default;
}

.modal-content {
  background: $white;
  padding: 20px;
  max-width: 97%;

  @include smd {
    max-width: 100%;
    max-height: 100%!important;
  }
}

.body {
  justify-content: flex-start;
  max-height: 96%;
}

.header {
  align-items: flex-start;

  .title {
    font-size: 22px;
    font-weight: 600;
    color: $black;

    @include smd {
      max-width: 80%;
      font-size: 18px;
    }
  }

  svg {
    margin-left: auto;

    @include smd {
      height: 25px;
      width: 25px;
      margin-top: 1px;
    }
  }
}

.no-padding {
  padding: 0;
}
</style>
